@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$gray: #333;
$text: #5C5B5F;
$yellow: #F4AA00;
$red: #881418;
$blue: #005487;
$grey: #808080;
$green: #09B197;
$lgreen: #83BD41;
$orange: #EF7622;

$iphone: 375px;
$iphone_p: 417px;
$ipad_p: 768px;
$asus: 960px;
$ipad_l: 1024px;
$_13: 1280px;
$_15: 1366px;

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius; }

@mixin background-size($size) {
	-webkit-background-size: $size;
	-moz-background-size: $size;
	-o-background-size: $size;
	background-size: $size; }

@mixin li-fix($width, $height) {
	width: $width;
	height: $height;
	background-repeat: no-repeat;
	background-position: center;
	border: 0 !important;
	vertical-align: middle;
	@media (max-width: $ipad_l) {
		width: $width / 1.3;
		height: $height / 1.3; }
	@media (max-width: $ipad_p) {
		width: $width * 1.3;
		height: $height * 1.3; }
	@media (max-width: $iphone_p) {
		width: $width;
		height: $height; } }

html, body {
	width: 100%;
	height: 100%; }

* {
	margin: 0; }

.container {
	@media (max-width: $asus) {
		width: initial; } }

.wrapper {
	min-height: 100%;
	height: auto !important;
	height: 100%;
	margin: 0 auto -40px;
	@media (max-width: $ipad_p) {
		margin: 0 auto -80px; } }

.footer {
	height: 40px;
	background-color: $gray !important;
	padding-top: 5px;
	position: fixed;
	width: 100vw;
	bottom: 0;
	h4 {
		color: white;
		font-size: 14px; }
	@media (max-width: $ipad_p) {
		height: 80px; } }

.social-footer {
	float: right !important; }
.h4-footer {
	float: left !important; }

img.social-icon {
	margin-top: 3px;
	width: 25px;
	height: 25px; }

.navbar {
	height: 80px;
	@media (max-width: $ipad_p) {
		height: 60px; } }

.navbar-default {
	background-color: white !important;
	@include border-radius(0px);
	border: 0; }

.navbar-nav {
	font-size: 14px;
	li {
		color: #5C5B5F;
		a {
			padding: 10px 15px;
			color: $text !important;
			&:hover {
 }				//font-weight: bold
			&.active {
				font-weight: bold; }
			b {
				cursor: default; } }
		form {
			padding: 8px 15px;
			input, button {
				background-color: $text;
				border: 0;
				color: white;
				outline: none;
				@media (max-width: $ipad_p) {
					@include border-radius(0); } }
			.input-search {
				background-color: $text;
				input {
					padding-left: 5px; }
				button {
					margin-left: -5px; } } } } }

.navbar-header img {
	padding: 10px 0px;
	max-height: 80px; }

.carousel {
	height: 100vh; }

.carousel-inner {
	@media (max-width: $asus) {
		overflow-y: scroll; } }

.carousel-inner .item /* Afecta todas las paginas LUL */ {
	height: 100vh;
	width: 100vw;
	background-repeat: no-repeat;
	@include background-size(cover); }

.carousel-inner.carousel-no-index .item {
	@media (max-width: $ipad_p) {
		height: auto; } }

.carousel-inner .item.strategics {
	background-position: center; }

.carousel-inner .item.bluewhale {
	background-position: center; }

.carousel-inner .item.miclinica {
	background-position: 50% -25%; }

.carousel-inner .item.construcciones {
	background-position: center; }

.carousel-inner .item.consulting {
	background-position: 50% -15%; }

.carousel-inner .item.gobajasur {
	background-position: 50% -35%; }

ol.carousel-indicators {
	top: 100px;
	width: 100vw;
	left: 0;
	margin-left: 0;
	height: 120px !important;
	@media (max-width: $_15) {
		height: 100px !important; }
	margin-top: -20px;
	padding: 5px 0px;
	background-color: rgba(255, 255, 255, 0.7);
	vertical-align: middle;
	li {
 }		//margin: 0 12px 0 0
	@media (max-width: $_15) {
		margin-top: -20px; }
	@media (max-width: $_15) {
		margin-top: -30px; }
	@media (max-width: $ipad_l) {
		margin-top: -20px; }
	@media (max-width: $ipad_p) {
		margin-top: -40px;
		background-color: transparent; } }

li.strategics {
	background-image: url('/logos/strategics.png');
	@include li-fix(244px,66px);
	@include background-size(244px);
	-webkit-filter: brightness(0) invert(0.5) {
    filter: brightness(0) invert(0.5); }
	@media (max-width: $_15) {
		@include background-size(244px / 1.5); }
	@media (max-width: $ipad_p) {
		display: none !important; } }

li.strategics.active {
	background-color: transparent;
	background-image: url('/logos/strategics.png');
	@include li-fix(244px,66px);
	@include background-size(244px);
	-webkit-filter: none {
    filter: none; }
	@media (max-width: $_15) {
		@include background-size(244px / 1.4); }
	@media (max-width: $ipad_p) {
		display: inline-block !important;
		@include background-size(244px * 1.3);
		margin-top: 20px; }
	@media (max-width: $iphone_p) {
		@include background-size(244px); } }

li.bluewhale {
	background-image: url('/logos/bluewhale.png');
	@include li-fix(187px,78px)	vertical-align: middle;
	@include background-size(187px);
	-webkit-filter: brightness(0) invert(0.5) {
    filter: brightness(0) invert(0.5); }
	@media (max-width: $_15) {
		@include background-size(187px / 1.5); }
	@media (max-width: $ipad_p) {
		display: none !important; } }

li.bluewhale.active {
	background-color: transparent;
	background-image: url('/logos/bluewhale.png');
	@include li-fix(187px,78px)	vertical-align: middle;
	@include background-size(187px);
	-webkit-filter: none;
	filter: none;
	@media (max-width: $_15) {
		@include background-size(187px / 1.4); }
	@media (max-width: $ipad_p) {
		display: inline-block !important;
		@include background-size(187px * 1.3);
		margin-top: 20px; }
	@media (max-width: $iphone_p) {
		@include background-size(187px); } }

li.miclinica {
	background-image: url('/logos/miclinica2.png');
	@include li-fix(150px,100px);
	@include background-size(150px);
	-webkit-filter: brightness(0) invert(0.5) {
    filter: brightness(0) invert(0.5); }
	@media (max-width: $_15) {
		@include background-size(150px / 1.5); }
	@media (max-width: $ipad_p) {
		display: none !important; } }

li.miclinica.active {
	background-color: transparent;
	background-image: url('/logos/miclinica.png');
	@include li-fix(150px,100px);
	@include background-size(150px);
	-webkit-filter: none {
    filter: none; }
	@media (max-width: $_15) {
		@include background-size(150px / 1.4); }
	@media (max-width: $ipad_p) {
		display: inline-block !important;
		@include background-size(150px * 1.3);
		margin-top: 20px; }
	@media (max-width: $iphone_p) {
		@include background-size(150px); } }

li.construcciones {
	background-image: url('/logos/grupocortes.png');
	@include li-fix(110px,104px);
	@include background-size(110px);
	-webkit-filter: brightness(0) invert(0.5) {
    filter: brightness(0) invert(0.5); }
	@media (max-width: $_15) {
		@include background-size(110px / 1.5); }
	@media (max-width: $ipad_p) {
		display: none !important; } }

li.construcciones.active {
	background-color: transparent;
	background-image: url('/logos/grupocortes.png');
	@include li-fix(110px,104px);
	@include background-size(110px);
	-webkit-filter: none {
    filter: none; }
	@media (max-width: $_15) {
		@include background-size(110px / 1.4); }
	@media (max-width: $ipad_p) {
		display: inline-block !important;
		@include background-size(110px * 1.3);
		margin-top: 20px; }
	@media (max-width: $iphone_p) {
		@include background-size(110px); } }

li.consulting {
	background-image: url('/logos/bajacortes.png');
	background-size: 70px 87px;
	@include li-fix(100px,100px);
	@include background-size(100px);
	-webkit-filter: brightness(0) invert(0.5) {
    filter: brightness(0) invert(0.5); }
	@media (max-width: $_15) {
		@include background-size(100px / 1.5); }
	@media (max-width: $ipad_p) {
		display: none !important; } }

li.consulting.active {
	background-color: transparent;
	background-image: url('/logos/bajacortes.png');
	background-size: 100px 100px;
	@include li-fix(100px,100px);
	@include background-size(100px);
	-webkit-filter: none {
    filter: none; }
	@media (max-width: $_15) {
		@include background-size(100px / 1.4); }
	@media (max-width: $ipad_p) {
		display: inline-block !important;
		@include background-size(100px * 1.3);
		margin-top: 20px; }
	@media (max-width: $iphone_p) {
		@include background-size(100px); } }

li.gobajasur {
	background-image: url('/logos/gobajasur.png');
	@include li-fix(261px,65px);
	@include background-size(261px);
	-webkit-filter: brightness(0) invert(0.5) {
    filter: brightness(0) invert(0.5); }
	@media (max-width: $_15) {
		@include background-size(261px / 1.5); }
	@media (max-width: $ipad_p) {
		display: none !important; } }

li.gobajasur.active {
	background-color: transparent;
	background-image: url('/logos/gobajasur.png');
	@include li-fix(261px,65px);
	@include background-size(261px);
	-webkit-filter: none {
    filter: none; }
	@media (max-width: $_15) {
		@include background-size(261px / 1.4); }
	@media (max-width: $ipad_p) {
		display: inline-block !important;
		@include background-size(261px * 1.3);
		margin-top: 20px; }
	@media (max-width: $iphone_p) {
		@include background-size(261px); } }

.corousel-title {
	position: relative;
	font-size: 30px;
	color: white;
	display: inline;
	@media (max-width: $_15) {
		font-size: 25px; }
	@media (max-width: $iphone_p) {
		padding-left: 10px !important;
		padding-right: 10px !important;
		font-size: 25px; }
	&.top-left {
		padding: 10px 10px 10px 15%;
		//top: 25%
		top: 80%;
		float: left;
		@media (max-width: $_15) {
			//top: 35%
			top: 80%; }
		@media (max-width: $asus) {
			//top: 45%
			top: 80%; }
		@media (max-width: $iphone_p) {
			//top: 35%
			top: 80%; } }
	&.bot-right {
		float: right;
		padding: 10px 15% 10px 10px;
		//top: 25%
		top: 80%;
		@media (max-width: $_15) {
			//top: 35%
			top: 80%; }
		@media (max-width: $asus) {
			//top: 45%
			top: 80%; }
		@media (max-width: $iphone_p) {
			text-align: right;
			//top: 35%
			top: 80%; } }
	&.bg-red {
		background-color: $red; }
	&.bg-blue {
		background-color: $blue; }
	&.bg-gray {
		background-color: $gray;
		@media (max-width: $iphone_p) {
			top: 35%; } }
	&.bg-green {
		background-color: $green; }
	&.bg-lgreen {
		background-color: $lgreen; }
	&.bg-orange {
		background-color: $orange; }
	&.ext-r-pad {
		padding: 10px 25% 10px 10px; }
	&.ext-l-pad {
		padding: 10px 10px 10px 25%;
		//@media (max-width: $_15)
		///padding-left: 15%;
		@media (max-width: $ipad_p) {
			padding-left: 5%; } } }

.container.top {
	padding-top: 100px;
	@media (max-width: $asus) {
		padding: 250px 0 50px 0;
		.row {
			margin: 0; } }
	@media (max-width: $ipad_p) {
		padding: 360px 0 50px 0; }
	@media (max-width: $iphone_p) {
		padding: 200px 0 80px 0; } }

.bg-white {
	background-color: white; }

.row-table {
	display: table; }

.row-table .col-table:last-child {
	@media (max-width: $ipad_p) {
		margin-top: 20px;
		margin-bottom: 20px; } }
.row-table .col-table:first-child {
	@media (max-width: $asus) {
		display: none; } }

.col-table {
	float: none;
	display: table-cell;
	vertical-align: top;
	img {
		margin: 0 auto;
		@media (max-width: $_15) {
			max-height: 500px; } }
	@media (max-width: $ipad_p) {
		width: 100vw;
		float: left;
		display: inline; } }

.tab-body {
	padding: 40px;
	@media (max-width: $_15) {
		padding: 20px; }
	@media (max-width: $ipad_p) {
		padding: 5px; }
	font-size: 16px;
	ul.nav-tabs {
		width: 100%;
		border: 0 !important;
		li {
			a {
				text-align: center;
				@include border-radius(0);
				border: 0 !important;
				background-color: $text;
				color: white; }
			&.active a {
				border: 0 !important;
				color: white;
				background-color: $yellow; }
			&.w25 {
				width: 25%;
				@media (max-width: $ipad_p) {
					width: 50%; } }
			&.w30 {
				width: 30%;
				@media (max-width: $ipad_p) {
					width: 100%; } }
			&.w33 {
				width: 33%;
				@media (max-width: $ipad_p) {
					width: 100%; } }
			&.w37 {
				width: 37%;
				@media (max-width: $ipad_p) {
					width: 100%; } }
			&.w40 {
				width: 40%;
				@media (max-width: $ipad_p) {
					width: 100%; } }
			&.w50 {
				width: 50%;
				@media (max-width: $ipad_p) {
					width: 100%; } }
			&.p10 {
				padding-left: 10px;
				@media (max-width: $ipad_p) {
					padding-left: 0; } }
			&.m10 {
				margin-top: 10px;
				@media (max-width: $ipad_p) {
					margin-top: 0; } }
			&.ml10 {
				margin-left: 10px;
				@media (max-width: $ipad_p) {
					margin-left: 0; } }
			@media (max-width: $ipad_p) {
				padding-top: 10px; }
			a.line {
				line-height: 19px !important;
				padding: 2px 15px !important;
				@media (max-width: $asus) {
					padding: 12px 15px !important; }
				@media (max-width: $ipad_p) {
					padding: 10px 15px !important; } } } }
	.tab-content {
		color: $text;
		padding-top: 40px;
		span {
			&.default, &.big, &.strategics, &.blueWhale, &.miclinica, &.construcciones, &.baja, &.gobajasur {
				color: $yellow;
				font-weight: bold;
				font-style: italic; }
			&.big, &.strategics, &.blueWhale, &.miclinica, &.construcciones, &.baja, &.gobajasur {
				font-size: 20px; }
			&.title {
				font-weight: bold;
				font-style: italic;
				font-size: 18px; }
			&.title-2 {
				font-weight: bold;
				font-style: italic; }
			&.strategics {
				color: $red; }
			&.blueWhale {
				color: $blue; }
			&.miclinica {
				color: $green; }
			&.construcciones {
				color: $orange; }
			&.baja {
				color: $blue; }
			&.gobajasur {
				color: $lgreen; } } } }

.tab-body ul.nav-tabs li.active a {
	&.strategics {
		background-color: $red; }
	&.blueWhale {
		background-color: $blue; }
	&.miclinica {
		background-color: $green; }
	&.construcciones {
		background-color: $orange; }
	&.baja {
		background-color: $blue; }
	&.gobajasur {
		background-color: $lgreen; } }

.tab-body ul.nav-tabs li {
	&.active a.yellow, a.yellow {
		background-color: $yellow; } }

a.yellow {
	background-color: $yellow;
	color: white;
	@include border-radius(0 !important); }
.nav-tabs > li > a.yellow:hover,
.nav-tabs > li > a.yellow:active,
.nav-tabs > li > a.yellow:focus,
.nav-tabs > li > a.yellow:visited {
	background-color: $yellow !important;
	color: white !important;
	@include border-radius(0 !important);
	border-color: $yellow; }

h3.contact {
	color: $yellow;
	font-style: italic; }

div.form-group.contact {
	color: $text;
	label {
		font-style: italic; }
	input, textarea, select, button {
		@include border-radius(0);
		border: 1px $yellow solid; }
	button {
		background-color: $yellow;
		color: white;
		padding: 6px 25px; } }

.logo-grupo {
	@media (max-width: $ipad_p) {
		height: 60px; } }

.navbar-collapse {
	@media (max-width: $ipad_p) {
		background-color: white; } }

.colla-cel {
	@media (max-width: $ipad_p) {
		padding: 0; } }

ul.btn-companies {
	position: absolute;
	bottom: 0;
	right: 0;
	margin-right: 55px;
	margin-bottom: 40px;
	@media (max-width: $asus) {
		position: relative;
		border: 0;
		float: right;
		margin-right: 20px; } }

.attention {
	margin-left: 20px;
	.tab-body {
		padding: 20px;
		.tab-content {
			padding-top: 0; } }
	@media (max-width: $ipad_p) {
		margin-left: 0; } }

.mar-bot {
	@media (max-width: $ipad_p) {
		margin-bottom: 100px; } }

.posts-content {
	height: 550px;
	overflow-y: scroll;
	padding-bottom: 30px;
	@media (max-width: $_15) {
		height: 500px; }
	@media (max-width: $asus) {
		padding-top: 20px;
		img {
			margin: 0 auto;
			margin-bottom: 15px; } }
	@media (max-width: $ipad_p) {
		height: auto;
		overflow-y: none; } }

.post-show {
	h4 {
		margin-top: 0; } }

.posts-content-right {
	margin-left: 30px;
	margin-right: -30px;
	@media (max-width: $ipad_l) {
		margin-left: 10px; }
	@media (max-width: $ipad_p) {
		margin: 50px 0 150px 0;
		padding-bottom: 50px; } }

#categoriesContent {
	height: 190px;
	overflow-y: scroll;
	@media (max-width: $ipad_p) {
		height: auto;
		overflow-y: none; } }

.cat-tp {
	@media (max-width: $ipad_p) {
		padding-top: 20px; } }

.investors-row {
	.col-md-6 {
		width: 65%; }
	@media (max-width: $asus) {
		.col-table:first-child {
			display: flex; }
		.col-md-6 {
			width: 100%; }
		.btn-contact {
			display: inherit;
			margin-left: 35px; } }
	@media (max-width: $ipad_p) {
		background-color: white;
		padding-bottom: 50px;
		.btn-contact {
			margin-left: 15px; } } }
